/*
 * Load I18n.js, translations.js and configure I18n
 */

import I18n from 'i18n.lib'
import en from 'translations/en.yml'
import enGB from 'translations/en-GB.yml'
import de from 'translations/de.yml'
import deAT from 'translations/de-AT.yml'
import deCH from 'translations/de-CH.yml'
import fr from 'translations/fr.yml'
import frCH from 'translations/fr-CH.yml'
import it from 'translations/it.yml'
import itCH from 'translations/it-CH.yml'
import esES from 'translations/es-ES.yml'
import nl from 'translations/nl.yml'
import pl from 'translations/pl.yml'
import zhCN from 'translations/zh-CN.yml'
import QueryString from 'libraries/queryString'

I18n.translations = Object.assign({},
    en,
    enGB,
    de,
    deAT,
    deCH,
    fr,
    frCH,
    it,
    itCH,
    esES,
    nl,
    pl,
    zhCN,
);
I18n.defaultLocale = 'en';

I18n.fallbacks = true;
// country specific locales like de-CH automatically fall back to
// their language
I18n.fallbackRules.en = ['de'];

if (QueryString.debugLocales) {
  I18n.t = function (key) {
    return key;
  }
}

export default I18n;

